import React, { useState, useEffect } from "react";
import getData from "../getdata/GetData";
import { FiTrash } from "react-icons/fi";
import $ from "jquery";
import { IoClose, IoDocumentOutline, IoCheckmarkCircle } from "react-icons/io5";
import GallerySlider from "components/general/GallerySlider";
import Notes from "./Notes";

function TicketTabs({
  idTicket,
  changeField,
  selectedFile,
  setChangeField,
  setSelectedFile,
  deleteFileFromState,
  addOnChange,
}) {
  const [ticketTab, setTicketTab] = useState([]);
  const [galleryTabsImages, setGalleryTabsImages] = useState([]);
  const [galleryTabsImageIndex, setGalleryTabsImageIndex] = useState("");
  const [indicateTabs, setIndicateTabs] = useState("");

  useEffect(() => {
    if (idTicket > 0) {
      getTicketTab("comments");
    }
  }, [idTicket]);

  let expand = "xxl";
  if (window.screen.width <= 576) {
    expand = "mobile";
  }

  async function getTicketTab(
    currentTab,
    id_item,
    action,
    type,
    value,
    id_file
  ) {
    const request_parameters = {
      module: "sd",
      block: "ticket_tabs",
    };
    const request_attribute = {
      id_ticket: idTicket,
      current: currentTab,
      id_item: id_item,
      action: action,
      id_file: id_file,
    };

    let request_data = [];

    if (action === "edit" || action === "add" || action === "answer") {
      request_data = {
        edit_field: type,
        value: value,
      };
    } else {
    }

    const result = await getData(
      request_parameters,
      request_attribute,
      request_data,
      selectedFile
    );

    const arrTicketTab = {
      items:
        result && result.request_data && result.request_data.items
          ? result.request_data.items
          : [],
      currentTab: currentTab,
    };

    const arrIndicateTabs =
      result && result.request_data && result.request_data.indicate_tabs
        ? result.request_data.indicate_tabs
        : [];

    setIndicateTabs(arrIndicateTabs);
    setTicketTab(arrTicketTab);
    setChangeField(false);
    setSelectedFile([]);
  }

  return (
    <>
      <div className="ticket_block_tab_button_content border_background">
        <div className="ticket-block-tab-button">
          <div
            className={`tab-comments tab-title-button ${
              ticketTab.currentTab === "comments" ? "active" : ""
            }`}
          >
            <div onClick={() => getTicketTab("comments")}>Комментарии</div>
          </div>

          <div
            className={`tab-notes tab-title-button ${
              ticketTab.currentTab === "notes" ? "active" : ""
            }`}
          >
            <div onClick={() => getTicketTab("notes")}>
              <div className="a-flex a-gap-5 a-center">
                <div>Заметки</div>

                {indicateTabs &&
                indicateTabs.notes &&
                indicateTabs.notes === "y" ? (
                  <IoCheckmarkCircle
                    style={{ color: "#2294d7", fontSize: "16px" }}
                  />
                ) : (
                  false
                )}
              </div>
            </div>
          </div>

          <div
            className={`tab-history tab-title-button ${
              ticketTab.currentTab === "logs" ? "active" : ""
            }`}
          >
            <div onClick={() => getTicketTab("logs")}>История</div>
          </div>

          {/* Два таба
          <div
            className={`tab-related-ticket tab-title-button ${
              ticketTab.currentTab === "tab-related-ticket" ? "active" : ""
            }`}
          >
            <div href="1#tab-related-ticket" onClick={() => setChangeField("tab-related-ticket")}>
              Связанные задачи
            </div>
          </div>
          <div className={`tab-time-used tab-title-button ${ticketTab.currentTab === "tab-time-used" ? "active" : ""}`}>
            <div href="1#tab-time-used" onClick={() => setChangeField("tab-time-used")}>
              Использованное время (ч., м.)
            </div>
          </div>
        */}
        </div>
        <div className="ticket-block-tab-content">
          {ticketTab.currentTab === "comments" ? (
            <div className="tab-content-info-comments">
              <div>
                <GallerySlider
                  galleryImages={galleryTabsImages}
                  galleryImageIndex={galleryTabsImageIndex}
                  setGalleryImageIndex={setGalleryTabsImageIndex}
                />
              </div>

              {Object.keys(ticketTab.items).map((comment_key, index) => {
                const comment = ticketTab.items[comment_key];
                if (comment) {
                  return (
                    <div
                      key={index}
                      className={`comment-text-all-block ${
                        comment.result === "y" ? "mark_result" : ""
                      }`}
                    >
                      <div className="comment-block-ticket ">
                        <div className="comment-text-user-date-message">
                          <div className="comment-user-date">
                            <div className="comment-user">{comment.user}</div>

                            <div className="comment-date">{comment.date}</div>
                          </div>

                          {comment.answer_to > 0 ? (
                            <div className="quote_tabs_comment">
                              <div className="quote_comment_user_block">
                                <div className="quote_comment_user">
                                  {ticketTab.items[comment.answer_to].user}
                                </div>
                              </div>
                              {ticketTab.items[comment.answer_to].text}
                            </div>
                          ) : (
                            false
                          )}

                          {"comment_" + comment.id === changeField ? (
                            <div className="edit-comment">
                              <div className="input-ticket-box-text">
                                <textarea
                                  id="textarea-comment"
                                  rows="3"
                                  defaultValue={
                                    comment.text ? comment.text : null
                                  }
                                />

                                <div className="save-cancel-textarea">
                                  <div className="">
                                    <form>
                                      <label htmlFor="btnFromAddFileCommentredactor">
                                        Прикрепить файл
                                      </label>
                                      <input
                                        className="class_display_none"
                                        id="btnFromAddFileCommentredactor"
                                        type="file"
                                        onChange={addOnChange}
                                      />
                                    </form>
                                  </div>
                                  <div
                                    className="save-textarea button-style"
                                    onClick={() => {
                                      var textareaValue =
                                        $("#textarea-comment").val();
                                      getTicketTab(
                                        "comments",
                                        comment.id,
                                        "edit",
                                        "text",
                                        textareaValue
                                      );
                                    }}
                                  >
                                    Сохранить
                                  </div>
                                  <div
                                    className="cancel-textarea button-style"
                                    onClick={() => {
                                      setChangeField(false);
                                      setSelectedFile([]);
                                    }}
                                  >
                                    Отменить
                                  </div>
                                </div>
                              </div>
                              <div className="addfile-block">
                                <div className="arr-map-block-img">
                                  {selectedFile.map((fileInState, index) => (
                                    <div
                                      key={index}
                                      className="arr-map-item-img"
                                    >
                                      {fileInState.name}
                                      <div
                                        onClick={() => {
                                          if (window.confirm("Удалить?")) {
                                            deleteFileFromState(index);
                                          }
                                        }}
                                      >
                                        <FiTrash />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="comment-message a-link">
                              <pre>
                                {comment.text
                                  .split(/(https?:\/\/[^\s]+)/g)
                                  .map((part, index) => {
                                    if (part.match(/https?:\/\/[^\s]+/)) {
                                      return (
                                        <a
                                          key={index}
                                          href={part}
                                          target="_blank"
                                        >
                                          {part}
                                        </a>
                                      );
                                    }
                                    return <span key={index}>{part}</span>;
                                  })}
                              </pre>
                            </div>
                          )}

                          <div className="comment-arr-map-files">
                            <div className="result_text">
                              Добавлен как результат
                            </div>
                            {comment.files ? (
                              <div className="comment-arr-map-files-items-block arr_map_files_image_block">
                                {comment.files.image ? (
                                  <div className="ticket_main_files-image_items">
                                    {comment.files.image.map(
                                      (fileImage, indexImage) => (
                                        <div
                                          key={indexImage}
                                          className="ticket_main_files-image_item"
                                        >
                                          <a>
                                            <img
                                              src={fileImage.url}
                                              onClick={() => {
                                                setGalleryTabsImages(
                                                  comment.files.image
                                                );
                                                setGalleryTabsImageIndex(
                                                  indexImage
                                                );
                                              }}
                                            />
                                          </a>
                                          <div
                                            className="ticket_main_files-image_del"
                                            onClick={() => {
                                              if (window.confirm("Удалить?")) {
                                                getTicketTab(
                                                  "comments",
                                                  comment.id,
                                                  "delete_file",
                                                  "",
                                                  "",
                                                  fileImage.id
                                                );
                                              }
                                            }}
                                          >
                                            <IoClose />
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  false
                                )}

                                {comment &&
                                comment.files &&
                                comment.files.other ? (
                                  <div className="ticket_main_files-other_items">
                                    {comment.files.other.map(
                                      (fileOther, indexOther) => (
                                        <div
                                          key={indexOther}
                                          className="ticket_main_files-other_item"
                                        >
                                          <a
                                            href={fileOther.url}
                                            target="_blank"
                                          >
                                            <div className="ticket_main_files-other_name">
                                              {fileOther.name}

                                              <IoDocumentOutline />
                                              {fileOther.size}
                                              <IoClose
                                                onClick={() => {
                                                  if (
                                                    window.confirm("Удалить?")
                                                  ) {
                                                    getTicketTab(
                                                      "comments",
                                                      comment.id,
                                                      "delete_file",
                                                      "",
                                                      "",
                                                      fileOther.id
                                                    );
                                                  }
                                                }}
                                              />
                                            </div>
                                          </a>
                                        </div>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  false
                                )}
                              </div>
                            ) : (
                              false
                            )}
                          </div>
                        </div>
                        <div className="comment-buttons-block">
                          {comment.owner === "Y" ? (
                            <div
                              className="comment-button button-style"
                              onClick={() => {
                                setChangeField("comment_" + comment.id);
                                setSelectedFile([]);
                              }}
                            >
                              Редактировать
                            </div>
                          ) : (
                            false
                          )}
                          {comment.owner === "Y" ? (
                            <div
                              className="comment-button button-style"
                              onClick={() =>
                                getTicketTab("comments", comment.id, "delete")
                              }
                            >
                              Удалить
                            </div>
                          ) : (
                            false
                          )}
                          <div
                            className="comment-button button-style"
                            onClick={() =>
                              setChangeField("answer_comment_" + comment.id)
                            }
                          >
                            Ответить
                          </div>

                          <div
                            className="comment-button button-style"
                            onClick={() =>
                              getTicketTab(
                                "comments",
                                comment.id,
                                "mark_result"
                              )
                            }
                          >
                            {comment.result === "y"
                              ? "Удалить результат"
                              : "Отметить как результат"}
                          </div>
                        </div>
                        {"answer_comment_" + comment.id === changeField ? (
                          <div className="edit-comment answer-comment-rumba">
                            <div className="input-ticket-box-text">
                              <textarea
                                id="textarea-comment"
                                rows="3"
                              ></textarea>

                              <div className="save-cancel-textarea">
                                <div
                                  className="save-textarea button-style"
                                  onClick={() => {
                                    var textareaValue =
                                      $("#textarea-comment").val();
                                    getTicketTab(
                                      "comments",
                                      comment.id,
                                      "answer",
                                      "text",
                                      textareaValue
                                    );
                                  }}
                                >
                                  Сохранить
                                </div>
                                <div
                                  className="cancel-textarea button-style"
                                  onClick={() => setChangeField(false)}
                                >
                                  Отменить
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          false
                        )}
                      </div>
                    </div>
                  );
                }
              })}
              <div className="add-comment-block">
                <div
                  className={`add-comment ${(expand = "mobile"
                    ? "add-comment-mobile"
                    : "")}`}
                >
                  <div className="input-ticket-box-text">
                    <textarea
                      className=""
                      id="textarea-comment"
                      rows="5"
                      defaultValue={""}
                    />

                    <div className="add_comment_button__addimage">
                      <div className="">
                        <form method="post" encType="multipart/form-data">
                          <label htmlFor="btnFromAddFileComment">
                            Прикрепить файл
                          </label>
                          <input
                            className="class_display_none"
                            id="btnFromAddFileComment"
                            type="file"
                            onChange={addOnChange}
                            multiple
                          />
                        </form>
                      </div>

                      <div
                        className="save-textarea button-style"
                        onClick={() => {
                          var textareaValue = $("#textarea-comment").val();
                          getTicketTab(
                            "comments",
                            "",
                            "add",
                            "text",
                            textareaValue
                          );
                          $("#textarea-comment").val("");
                        }}
                      >
                        {
                          (expand = "mobile" ? (
                            <div>Добавить</div>
                          ) : (
                            <div>Добавить комментарий</div>
                          ))
                        }
                      </div>
                    </div>
                  </div>

                  {(changeField &&
                    !changeField.includes("answer_comment_") &&
                    !changeField.includes("comment_") &&
                    !changeField.includes("description")) ||
                  !changeField ? (
                    <div className="addfile-block">
                      <div className="arr-map-block-img">
                        {selectedFile.map((fileInState, index) => (
                          <div key={index} className="arr-map-item-img">
                            <img
                              src={URL.createObjectURL(fileInState)}
                              alt={index}
                            ></img>
                            <div
                              onClick={() => {
                                if (window.confirm("Удалить?")) {
                                  deleteFileFromState(index);
                                }
                              }}
                            >
                              <FiTrash />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    false
                  )}
                </div>
              </div>
            </div>
          ) : (
            false
          )}

          {ticketTab.currentTab === "notes" ? (
            <Notes idTicket={idTicket} />
          ) : (
            false
          )}

          {ticketTab.currentTab === "logs" ? (
            <div className="tab-content-info-logs">
              {ticketTab.items.map((logs, index) => (
                <div key={index} className="logs-text-all-block">
                  <div>{logs.date}</div>
                  <div>{logs.user}</div>

                  <div>{logs.text}</div>
                </div>
              ))}
            </div>
          ) : (
            false
          )}
        </div>
      </div>
    </>
  );
}

export default TicketTabs;
